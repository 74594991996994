import * as yup from 'yup'

export const states = {
  NA: '-',
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

// Define the validation schema using yup
export const editUserSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  margin: yup
    .number()
    .required('Margin is required')
    .min(1, 'Minimum at least 1')
    .max(99, 'Allowed maximum is 99'),
  location: yup.string().required('Location required'),
  permissionLevel: yup.string().required('Permission level required'),
  streetAddress: yup
    .string()
    .required('Address is required')
    .test('streetAddress', 'Sorry, you must have a physical address, not a PO Box', (value) => {
      if (!value) return false
      const results = value.match(
        /(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i
      )
      return !(results && results.length)
    }),
  streetAddress2: yup
    .string()
    .nullable()
    .test('streetAddress', 'Sorry, you must have a physical address, not a PO Box', (value) => {
      if (!value) return true
      const results = value.match(
        /(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i
      )
      return !(results && results.length)
    }),
  city: yup.string().required('City is required'),
  state: yup
    .string()
    .required('State is required')
    .test('state', 'Must be a valid state', (value) => {
      if (!value) return true
      return value !== '-'
    }),
  postalCode: yup.string().required('Postal code is required'),
})

export const passwordSchema = yup.object({
  password: yup
    .string()
    .required('Please enter a password')
    .matches(
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}/,
      'Password must contain at least 8 characters, one uppercase and one number '
    ),
})

export const newUserSchema = passwordSchema.concat(editUserSchema)

export interface EditUserBodyProps {
  firstName: string
  lastName: string
  email: string
  margin: number
  location: string
  isBuyer: boolean
  isAdmin: boolean
  isGuest: boolean
  isCustomer: boolean
  streetAddress: string
  streetAddress2?: string
  city: string
  state: string
  postalCode: string
  userId: string
  userPermission: boolean
  license?: string
}

export interface AddUserBodyProps extends EditUserBodyProps {
  password: string
}
