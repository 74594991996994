import { IBusiness } from './business'
export interface IUser {
  _id: string
  email: string
  firstName: string
  lastName: string
  isBuyer: boolean
  isAdmin: boolean
  isGuest: boolean
  isCustomer: boolean
  isPartner: boolean
  margin: number
  photo: string
  license: string
  location: string
  killed: boolean
  dateAdded: Date
  canSeeInvoiceData: boolean
  streetAddress: string
  streetAddress2?: string
  city: string
  state: string
  postalCode: string
  business?: IBusiness
}

export const locations = [
  { text: 'All', value: 'all' },
  { text: 'Rhode Island', value: 'rhodeIsland' },
  { text: 'Minnesota', value: 'minnesota' },
  { text: 'Florida', value: 'florida' },
  { text: 'Arizona', value: 'arizona' },
  { text: 'Chicago', value: 'chicago' },
  { text: 'Brooklyn', value: 'brooklyn' },
]

export const permissions = [
  { text: 'Guest', value: 'guest' },
  { text: 'Customer', value: 'customer' },
  { text: 'Buyer', value: 'buyer' },
  { text: 'Admin', value: 'admin' },
  { text: 'Partner', value: 'partner' },
]
