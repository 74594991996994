import React from 'react'
import { Container, Row, Col, Card, Alert } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import {
  fetchCart,
  addToCart,
  subtractCart,
  abandonCart,
  editItemPrice,
  creditCart,
  updatePaymentMethod,
  RootState,
  AppDispatch,
} from '../../store'
import { connect } from 'react-redux'
import AbandonCartModal from './AbandonCartModal'
import EditCartPriceModal from './EditCartPriceModal'
import CreditCartModal from './CreditCartModal'
import { PencilSquare } from 'react-bootstrap-icons'
import { calculateTotalCost } from '../util/utils'
import NoCart from './NoCart'
import CartItem from './CartItem'
import CartSummary from './CartSummary'
import { ICart } from '../../types/cart'

interface CartProps {
  cart: ICart
  fetchCart: () => void
  abandonCart: (body: any) => void
  editItemPrice: (body: any) => void
  creditCart: (body: any) => void
  updatePaymentMethod: (body: any) => void
}

const Cart = ({
  cart,
  fetchCart,
  abandonCart,
  editItemPrice,
  creditCart,
  updatePaymentMethod,
}: CartProps) => {
  const [showAbandonCartModal, setShowAbandonCartModal] = useState(false)
  const [showEditPriceModal, setShowEditPriceModal] = useState(false)
  const [showCreditCartModal, setShowCreditCartModal] = useState(false)

  const [editItem, setEditItem] = useState({ price: 0 })
  const [searchValue, setSearchValue] = useState('')
  const [payWithCheck, setPaywithCheck] = useState(false)

  useEffect(() => {
    if (!cart) {
      fetchCart()
    }
  }, [])

  const closeAbandonCartModal = () => {
    setShowAbandonCartModal(false)
    abandonCart({ cartId: cart._id })
  }

  const closeEditPriceModal = (body) => {
    setShowEditPriceModal(false)
    setEditItem({ price: 0 })
    editItemPrice(body)
  }

  const closeCreditCartModal = (body) => {
    setShowCreditCartModal(false)
    creditCart(body)
  }

  const updatePayment = () => {
    setPaywithCheck(!payWithCheck)
    updatePaymentMethod({ cartId: cart._id, payWithCheck: !payWithCheck })
  }

  if (!cart) {
    return <NoCart searchValue={searchValue} setSearchValue={setSearchValue} />
  }

  const renderCartCredit = () => {
    return (
      <Card.Body>
        <Row>
          <Col>Credit Applied</Col>
          <Col>1</Col>
          <Col>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(cart.credit)}
            <PencilSquare
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                setShowCreditCartModal(true)
              }}
            />
          </Col>
          <Col
            xs="3"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(cart.credit)}
          </Col>
        </Row>
      </Card.Body>
    )
  }

  const renderNoItemsAlert = () => {
    return (
      <Alert>
        You have no items in your cart.
        <Alert.Link href="/dashboard"> Return to the dashboard</Alert.Link> to begin adding pieces.
      </Alert>
    )
  }

  const renderCartHeader = () => {
    return (
      <Card.Header>
        <Row>
          <Col xs="3">
            <strong>Serial Number</strong>
          </Col>
          <Col xs="3">
            <strong>Quantity</strong>
          </Col>
          <Col xs="3">
            <strong>Price</strong>
          </Col>
          <Col
            xs="3"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <strong>Cost</strong>
          </Col>
        </Row>
      </Card.Header>
    )
  }

  const renderCartCost = () => {
    return (
      <Card.Footer>
        <Col
          xs="12"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <strong>{calculateTotalCost(cart.items, cart.credit)}</strong>
          </div>
        </Col>
      </Card.Footer>
    )
  }

  return (
    <Container fluid>
      <Row>
        <Col lg="9" className="mt-3">
          {!cart.items.length ? (
            renderNoItemsAlert()
          ) : (
            <Card>
              {renderCartHeader()}
              <br />
              {cart.items.map((item: any, idx: number) => {
                return (
                  <div key={`${item._id}` + `${item.sizeModifier}`}>
                    <CartItem
                      item={item}
                      idx={idx}
                      setEditItem={setEditItem}
                      setShowEditPriceModal={setShowEditPriceModal}
                      cartSize={cart.items.length}
                      cartId={cart._id}
                    />
                  </div>
                )
              })}
              {cart.credit > 0 && renderCartCredit()}
              {renderCartCost()}
            </Card>
          )}
        </Col>
        <Col lg="3" className="mt-3">
          <CartSummary
            cart={cart}
            setShowAbandonCartModal={setShowAbandonCartModal}
            updatePayment={updatePayment}
            setShowCreditCartModal={setShowCreditCartModal}
          />
        </Col>
      </Row>
      <AbandonCartModal
        show={showAbandonCartModal}
        abandonCart={closeAbandonCartModal}
        toggle={() => setShowAbandonCartModal(false)}
      />
      <EditCartPriceModal
        show={showEditPriceModal}
        editPrice={closeEditPriceModal}
        toggle={() => setShowEditPriceModal(false)}
        item={editItem}
        cart={cart}
      />
      <CreditCartModal
        show={showCreditCartModal}
        creditCart={closeCreditCartModal}
        toggle={() => setShowCreditCartModal(false)}
        cart={cart}
      />
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    cart: state.cart.data,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchCart() {
      dispatch(fetchCart())
    },

    abandonCart(body) {
      dispatch(abandonCart(body))
    },
    editItemPrice(body) {
      dispatch(editItemPrice(body))
    },
    creditCart(body) {
      dispatch(creditCart(body))
    },
    updatePaymentMethod(body) {
      dispatch(updatePaymentMethod(body))
    },
  }
}

export default connect(mapState, mapDispatch)(Cart)
