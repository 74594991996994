import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { IUser } from '../../../types/user'

interface DeleteUserModalProps {
  showDeleteUserModal: boolean
  deleteSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  selectedUser: IUser
  setShowDeleteModal: (arg: boolean) => void
  userPermission: boolean
}

const DeleteUserModal = ({
  showDeleteUserModal,
  deleteSelectedUser,
  selectedUser,
  setShowDeleteModal,
  userPermission,
}: DeleteUserModalProps) => {
  const handleDelete = () => {
    deleteSelectedUser({ userId: selectedUser._id, userPermission })
    setShowDeleteModal(false)
  }
  return (
    <Modal show={showDeleteUserModal} centered>
      <Modal.Header>Confirm Delete User</Modal.Header>
      <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowDeleteModal(false)} variant="danger">
          Close
        </Button>
        <Button onClick={handleDelete} variant="success">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteUserModal
