import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import {
  RootState,
  AppDispatch,
  updateUserThunk,
  fetchSelectedUserThunk,
  deleteSelectedUserThunk,
  reenableSelectedUserThunk,
  updatePasswordThunk,
} from '../../../store'
import { Container, Row, Col, Form, Card, Button, FloatingLabel } from 'react-bootstrap'
import { startCase } from 'lodash'
import { locations, permissions } from '../../../types/user'
import { editUserSchema, states, EditUserBodyProps } from './AddUser.util'
import { toast } from 'react-toastify'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { IUser } from '../../../types/user'
import { Link, useParams } from 'react-router-dom'
import DeleteUserModal from './DeleteUserModal'
import ReenableUserModal from './ReenableUserModal'
import UpdatePasswordModal from './UpdatePasswordModal'

interface EditUserProps {
  selectedUser: IUser
  newUserLoading: boolean
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  updateUser: (body: EditUserBodyProps) => void
  fetchSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  deleteSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  reenableSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  updatePassword: (body: { userId: string; newPassword: string }) => void
}

const EditUser: React.FC<PropsFromRedux> = ({
  selectedUser,
  newUserLoading,
  isAdmin,
  isBuyer,
  isPartner,
  updateUser,
  fetchSelectedUser,
  deleteSelectedUser,
  reenableSelectedUser,
  updatePassword,
}: EditUserProps) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | ArrayBuffer | null>(
    selectedUser?.license || null
  )
  const [licenseUpdated, setLicenseUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReeneableModal, setShowReenableModal] = useState(false)

  const showDeleteButton = isAdmin && selectedUser.killed === false
  const showReenableButton = isAdmin && selectedUser.killed === true

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const { id: selectedUserId } = useParams<{ id: string }>()

  useEffect(() => {
    const userPermission = isAdmin || isBuyer

    if (selectedUserId) {
      fetchSelectedUser({ userId: selectedUserId, userPermission })
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    setImagePreviewUrl(selectedUser?.license)
  }, [selectedUser])

  const getInitialPermissionLevel = () => {
    if (selectedUser?.isAdmin) return 'admin'
    if (selectedUser?.isBuyer) return 'buyer'
    if (selectedUser?.isGuest) return 'guest'
    if (selectedUser?.isCustomer) return 'customer'
    if (selectedUser?.isPartner) return 'partner'
    return 'customer' //default to customer
  }

  const formik = useFormik({
    initialValues: {
      firstName: selectedUser?.firstName || '',
      lastName: selectedUser?.lastName || '',
      email: selectedUser?.email || '',
      password: 'A1placeholder',
      margin: selectedUser?.margin * 100 || '',
      location: selectedUser?.location || '',
      permissionLevel: getInitialPermissionLevel() || '',
      streetAddress: selectedUser?.streetAddress || '',
      streetAddress2: selectedUser?.streetAddress2 || '',
      city: selectedUser?.city || '',
      state: selectedUser?.state || '',
      postalCode: selectedUser?.postalCode || '',
    },
    validationSchema: editUserSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!imagePreviewUrl) {
        toast.error(`Please upload a license.`)
      } else {
        const {
          firstName,
          lastName,
          email,
          password,
          margin,
          location,
          permissionLevel,
          streetAddress,
          streetAddress2,
          city,
          state,
          postalCode,
        } = values

        const body = {
          userId: selectedUser._id,
          firstName: startCase(firstName),
          lastName: startCase(lastName),
          email: email.trim().toLowerCase(),
          password,
          margin: Number(margin),
          location,
          isBuyer: permissionLevel === 'buyer',
          isAdmin: permissionLevel === 'admin',
          isGuest: permissionLevel === 'guest',
          isCustomer: permissionLevel === 'customer',
          isPartner: permissionLevel === 'partner',
          streetAddress: startCase(streetAddress.trim()),
          streetAddress2: startCase(streetAddress2.trim()),
          city: startCase(city.trim()),
          state,
          postalCode: postalCode.trim(),
          userPermission: isAdmin || isBuyer,
        }

        if (licenseUpdated) {
          body.license = imagePreviewUrl
        }

        updateUser(body)
        setLicenseUpdated(false)
      }
    },
  })

  const getPhoto = (e) => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result)
      setLicenseUpdated(true)
    }

    reader.readAsDataURL(file)
  }

  let imagePreview
  if (imagePreviewUrl) {
    imagePreview = <img className="edit-img" src={String(imagePreviewUrl)} />
  } else {
    imagePreview = <img className="edit-img" />
  }

  const formTouched = Object.keys(formik.touched).length > 0

  const hasFormikErrors = Object.keys(formik.errors).length > 0

  const disableSubmit = (hasFormikErrors || (!formTouched && !licenseUpdated)) && !licenseUpdated

  return (
    <Container fluid>
      {loading ? (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <Link to="/admin/view/users" className="ui button dashboard-add-converter-btn">
                Back To All Users
              </Link>
            </Col>
          </Row>
          <Card className="mt-3">
            <Card.Header>
              <h3>Edit User</h3>
            </Card.Header>
            <Card.Body>
              {newUserLoading ? (
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
                  </Col>
                </Row>
              ) : (
                <>
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div className="edit-img-container">{imagePreview}</div>
                          <input
                            type="file"
                            className="inputfile"
                            id="embedpollfileinput"
                            accept="image/png, image/jpeg"
                            onChange={getPhoto}
                          />
                          <div style={{ height: '40px' }}>
                            <label htmlFor="embedpollfileinput" className="ui blue button">
                              <i className="ui upload icon" />
                              Upload License
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group>
                          <FloatingLabel label="First name" className="mt-3">
                            <Form.Control
                              type="text"
                              name="firstName"
                              placeholder="First name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              isValid={formik.touched.firstName && !formik.errors.firstName}
                            />
                          </FloatingLabel>
                          {formik.touched.firstName && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.firstName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Last name" className="mt-3">
                            <Form.Control
                              type="text"
                              name="lastName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                              placeholder="Last name"
                              isValid={formik.touched.lastName && !formik.errors.lastName}
                            />
                          </FloatingLabel>
                          {formik.touched.lastName && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.lastName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Email" className="mt-3">
                            <Form.Control
                              type="text"
                              name="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              placeholder="Email"
                              isValid={formik.touched.email && !formik.errors.email}
                            />
                          </FloatingLabel>
                          {formik.touched.email && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        {!isPartner && (
                          <Form.Group>
                            <FloatingLabel label="Margin" className="mt-3">
                              <Form.Control
                                type="number"
                                name="margin"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.margin}
                                placeholder="margin"
                                isValid={formik.touched.margin && !formik.errors.margin}
                              />
                            </FloatingLabel>
                            <Form.Text id="marginHelpBlock" muted>
                              Must be a number at least 1 and under 100
                            </Form.Text>
                            {formik.touched.margin && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.margin}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        )}

                        {isAdmin && (
                          <Form.Group>
                            <FloatingLabel label="Set Permission Level" className="mt-3">
                              <Form.Control
                                id="permissionLevel"
                                name="permissionLevel"
                                as="select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.permissionLevel}
                                isValid={
                                  formik.touched.permissionLevel && !formik.errors.permissionLevel
                                }
                              >
                                <option key="placeholder" value="">
                                  -
                                </option>
                                {permissions.map((p, idx) => {
                                  return (
                                    <option key={p.value} value={p.value}>
                                      {p.text}
                                    </option>
                                  )
                                })}
                              </Form.Control>
                            </FloatingLabel>

                            {formik.touched.permissionLevel && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.permissionLevel}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        )}
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group controlId="streetAddress">
                          <FloatingLabel label="Address Line 1" className="mt-3">
                            <Form.Control
                              type="text"
                              name="streetAddress"
                              placeholder="Street address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress}
                              isValid={formik.touched.streetAddress && !formik.errors.streetAddress}
                            />
                          </FloatingLabel>
                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.streetAddress}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Address Line 2" className="mt-3">
                            <Form.Control
                              id="streetAddress2"
                              name="streetAddress2"
                              placeholder="streetAddress2"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress2}
                              isValid={
                                formik.touched.streetAddress2 && !formik.errors.streetAddress2
                              }
                            />
                          </FloatingLabel>
                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.streetAddress2}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="City" className="mt-3">
                            <Form.Control
                              id="city"
                              name="city"
                              type="text"
                              placeholder="City"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                              isValid={formik.touched.city && !formik.errors.city}
                            />
                          </FloatingLabel>
                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.city}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="State" className="mt-3">
                            <Form.Control
                              id="state"
                              name="state"
                              as="select"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                              isValid={formik.touched.state && !formik.errors.state}
                            >
                              {Object.entries(states).map(([key, value]) => {
                                return <option key={key}>{value}</option>
                              })}
                            </Form.Control>
                          </FloatingLabel>

                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.state}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Postal Code" className="mt-3">
                            <Form.Control
                              id="postalCode"
                              name="postalCode"
                              type="text"
                              placeholder="Postal Code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.postalCode}
                              isValid={formik.touched.postalCode && !formik.errors.postalCode}
                            />
                          </FloatingLabel>

                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.postalCode}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        {(isAdmin || isBuyer) && (
                          <Form.Group>
                            <FloatingLabel label="Set Primary Location" className="mt-3">
                              <Form.Control
                                id="location"
                                name="location"
                                as="select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                                isValid={formik.touched.location && !formik.errors.location}
                              >
                                {locations.map((location) => {
                                  if (location.value === 'all')
                                    return (
                                      <option key="placeholder" value={''}>
                                        -
                                      </option>
                                    )
                                  return (
                                    <option key={location.value} value={location.value}>
                                      {location.text}
                                    </option>
                                  )
                                })}
                              </Form.Control>
                            </FloatingLabel>

                            {formik.touched.location && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.location}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                  </Form>
                  <Row className="mt-5 d-flex justify-content-around">
                    <Col xs={6} sm={4}>
                      <Button
                        disabled={disableSubmit}
                        onClick={() => formik.submitForm()}
                        style={{ width: `100%` }}
                      >
                        Update User
                      </Button>
                    </Col>
                    {showReenableButton && (
                      <Col xs={6} sm={4}>
                        <Button
                          variant="secondary"
                          onClick={() => setShowReenableModal(true)}
                          style={{ width: `100%` }}
                        >
                          Re-enable User
                        </Button>
                      </Col>
                    )}
                    <Col xs={6} sm={4}>
                      <Button
                        variant="secondary"
                        onClick={() => setShowUpdatePasswordModal(true)}
                        style={{ width: `100%` }}
                      >
                        Update Password
                      </Button>
                    </Col>
                    {showDeleteButton && (
                      <Col xs={6} sm={4}>
                        <Button
                          variant="danger"
                          onClick={() => setShowDeleteModal(true)}
                          style={{ width: `100%` }}
                        >
                          Delete User
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {showReeneableModal && (
                <ReenableUserModal
                  showReeneableUserModal={showReeneableModal}
                  reenableSelectedUser={reenableSelectedUser}
                  selectedUser={selectedUser}
                  setShowReenableModal={setShowReenableModal}
                  userPermission={isAdmin || isBuyer}
                />
              )}
              {showDeleteModal && (
                <DeleteUserModal
                  showDeleteUserModal={showDeleteModal}
                  deleteSelectedUser={deleteSelectedUser}
                  selectedUser={selectedUser}
                  setShowDeleteModal={setShowDeleteModal}
                  userPermission={isAdmin || isBuyer}
                />
              )}
              {showUpdatePasswordModal && (
                <UpdatePasswordModal
                  showUpdatePasswordModal={showUpdatePasswordModal}
                  updatePassword={updatePassword}
                  selectedUser={selectedUser}
                  setShowUpdatePasswordModal={setShowUpdatePasswordModal}
                />
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
  isBuyer: state.user.isBuyer,
  isPartner: state.user.isPartner,
  newUserLoading: state.admin.newUserLoading,
  selectedUser: state.admin.selectedUser,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateUser: (body: EditUserBodyProps) => dispatch(updateUserThunk(body)),
    fetchSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(fetchSelectedUserThunk(body)),
    deleteSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(deleteSelectedUserThunk(body)),
    reenableSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(reenableSelectedUserThunk(body)),
    updatePassword: (body: { userId: string; newPassword: string }) =>
      dispatch(updatePasswordThunk(body)),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditUser)
