export function extractId(url) {
  // /admin/edit-user-2
  const urlArr = url.split('/')
  const pathArr = urlArr[urlArr.length - 1].split('-')
  const id = pathArr[pathArr.length - 1]
  return id
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function convertToInteger(margin) {
  margin = Number(margin)
  margin = margin * 100
  margin = margin.toFixed(0)

  return margin
}

export function validateMargin(margin) {
  const marginNumber = Number(margin)
  if (marginNumber && marginNumber >= 1 && marginNumber < 99.49) {
    return true
  } else {
    return false
  }
}

export function validateName(name) {
  if (name.length === 0 || name.length > 20) {
    return false
  } else {
    return true
  }
}

export function capitalizeFirstLetter(name) {
  name.charAt(0).toUpperCase()
  return name
}

export function validatePassword(password) {
  var mediumRegex = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})))'
  )

  const test = mediumRegex.test(password)
  return test
}
