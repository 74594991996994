import React from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  fetchAllUsersThunk,
  addToCart,
  reAddKilledConverter,
  setSearch,
  setMake,
  AppDispatch,
  RootState,
} from '../../store'
import IndividualConverter from './IndividualConverter'
import { ICart, ICartRequest } from '../../types/cart'
import { IConverter } from '../../types/converter'

interface ConverterListProps {
  converters: Array<IConverter>
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  cart: ICart
  addToCart: (body: ICartRequest) => void
  reAddKilledConverter: (body: IConverter) => void
  setSearchTerm: (term: string) => void
  setMakeTerm: (term: string) => void
}

const ConverterList = ({
  converters,
  isAdmin,
  isBuyer,
  isPartner,
  cart,
  addToCart,
  reAddKilledConverter,
  setSearchTerm,
  setMakeTerm,
}: ConverterListProps) => {
  return (
    <>
      <Row style={{ width: '100%' }} className="mt-3">
        {converters.map((converter) => {
          return (
            <IndividualConverter
              key={converter._id}
              converter={converter}
              cart={cart}
              isAdmin={isAdmin}
              isBuyer={isBuyer}
              isPartner={isPartner}
              addToCart={addToCart}
              reAddKilledConverter={reAddKilledConverter}
              setSearchTerm={setSearchTerm}
              setMakeTerm={setMakeTerm}
            />
          )
        })}
      </Row>
    </>
  )
}

const mapState = (state: RootState) => {
  return {
    cart: state.cart.data,
    user: state.user,
    isAdmin: state.user.isAdmin,
    isBuyer: state.user.isBuyer,
    isPartner: state.user.isPartner,
    allUsers: state.admin.allUsers,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchAllUsers() {
      dispatch(fetchAllUsersThunk())
    },
    addToCart(body) {
      dispatch(addToCart(body))
    },
    reAddKilledConverter(body) {
      dispatch(reAddKilledConverter(body))
    },
    setSearchTerm: (term) => dispatch(setSearch(term)),
    setMakeTerm: (term) => dispatch(setMake(term)),
  }
}

export default connect(mapState, mapDispatch)(ConverterList)
