export function filterFunction(converters, value) {
  const filtered = converters.filter((converter) => {
    const serialNumber = String(converter.serialNumber).toLowerCase()
    let converterWithoutSpaceOrHyphen = serialNumber.replace(/-|\s/g, '')

    let currentMake = String(converter.make).toLowerCase()

    if (converterWithoutSpaceOrHyphen.includes(value)) {
      return converter
    }

    if (serialNumber.includes(value)) {
      return converter
    }

    if (currentMake.includes(value)) {
      return converter
    }
  })
  const sorted = filtered.sort((a, b) => a.id - b.id)
  return sorted
}

/*
  options: [
        {
          key: 'common grade',
          text: 'Common Grade',
          value: 'common grade'
        }
      ] */
export function createMakeOptions(makes) {
  let options = [{ key: 'all converters', value: 'all converters', text: 'All Converters' }]
  makes.forEach((make) => {
    make = make.make
    let makeObj = {}
    makeObj.text = make
    make = make.toLowerCase()
    makeObj.key = make
    makeObj.value = make
    options.push(makeObj)
  })

  return options
}

export const calculateTotalCost = (items, credit) => {
  let cost = credit
  items.forEach((item) => {
    cost += item.quantity * item.price
  })
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cost)
}

export const calculateTotalPieces = (items) => {
  let total = 0
  items.forEach((item) => {
    total += item.quantity
  })
  return total
}

export const generateLabels = (cart) => {
  const map = {}

  cart &&
    cart.items.forEach((item) => {
      /**
       * We show each common grade category and break all other pieces
       * into their make
       */

      if (item.label === 'Common Grade') {
        item.label = item.serialNumber
      }
      if (!map[item.label]) {
        map[item.label] = {
          quantity: item.quantity,
          cost: item.quantity * item.price,
        }
      } else {
        map[item.label] = {
          quantity: map[item.label].quantity + item.quantity,
          cost: item.quantity * item.price + map[item.label].cost,
        }
      }
    })

  let labels = []

  Object.keys(map).forEach((key) => {
    map[key].label = key
    labels.push(map[key])
  })

  if (cart && cart.credit > 0) {
    labels.push({
      label: 'Credit',
      cost: cart.credit,
      quantity: 1,
    })
  }

  return labels
}

export const calculateAllInvoiceValue = (invoices) => {
  let value = 0
  if (!invoices) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)
  }

  invoices.forEach((invoice) => {
    value += invoice.cost
  })
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

export function formatLocation(location) {
  if (!location) {
    return ``
  }
  if (location.includes('rhodeIsland')) {
    return 'Rhode Island'
  } else {
    return `${location[0].toUpperCase()}${location.slice(1, location.length)}`
  }
}

export const decimalToFraction = (dec) => {
  const hash = {
    0.25: '1/4',
    0.5: '1/2',
    0.75: '3/4',
    0.33: '1/3',
    0.66: '2/3',
    1: 'Full',
  }
  return hash[dec]
}
