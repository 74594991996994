import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Card, Button, Table } from 'react-bootstrap'
import {
  RootState,
  AppDispatch,
  fetchBuyerProfitabilityData,
  fetchAllBuyers,
  clearProfitabilityData,
  useAppDispatch,
} from '../../store'
import { useFormik } from 'formik'
import * as yup from 'yup' // for everything
import { connect } from 'react-redux'
import DatePicker from 'react-date-picker'
import { ScaleLoader } from 'react-spinners'
import { format } from 'date-fns'
import { IUser, locations } from '../../types/user'
import { IBuyerItem } from '../../types/invoice'
import { convertJsonToCsv, profitabilityJsonToCsv } from '../util/jsonToCsv'
import { startCase } from 'lodash'

const schema = yup.object({
  startDate: yup.date().required('Start date required.'),
  endDate: yup
    .date()
    .required('End date required.')
    .min(yup.ref('startDate'), "End date can't be before start date."),
  buyerId: yup.string().test('is-not-dash', 'Must pick a buyer.', (val) => {
    return val !== '-'
  }),
})

interface BuyerProfitabilityDataProps {
  user: IUser
  historicalBuyerProfitabilityData: IBuyerItem[]
  refetchAllBuyers: () => void
  buyers: IUser[]
  clearProfitabilityData: () => void
}

const BuyerProfitabilityData = ({
  user,
  historicalBuyerProfitabilityData,
  refetchAllBuyers,
  buyers,
  clearProfitabilityData,
}: BuyerProfitabilityDataProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch: any = useAppDispatch()

  const { isAdmin, canSeeInvoiceData } = user || {}

  useEffect(() => {
    clearProfitabilityData()
    refetchAllBuyers()
    setIsLoading(false)
  }, [])

  const formik = useFormik({
    initialValues: {
      startDate: new Date().toString(),
      endDate: new Date().toString(),
      buyerId: isAdmin ? 'all' : user._id,
      location: isAdmin ? 'all' : user.location,
    },
    validationSchema: schema,
    onSubmit: ({ startDate, endDate, buyerId, location }) => {
      setIsLoading(true)
      dispatch(
        fetchBuyerProfitabilityData({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          buyerId,
          location,
        })
      ).then(() => setIsLoading(false))
      formik.setFieldTouched('startDate', false)
      formik.setFieldTouched('endDate', false)
      formik.setFieldTouched('buyer', false)
    },
  })

  const downloadCsv = () => {
    const propertiesToExclude = ['_id']
    // Convert JSON data to CSV format
    const csvContent = convertJsonToCsv(historicalBuyerProfitabilityData, propertiesToExclude)

    // Create a Blob object with CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a temporary anchor element
    const link = document.createElement('a')

    // Set anchor's properties
    link.href = URL.createObjectURL(blob)
    link.download = `invoices_${formik.values.buyerId}_${format(
      new Date(formik.values.startDate),
      'P'
    )}_to_${format(new Date(formik.values.endDate), 'P')}.csv`

    // Append the anchor element to the document body
    document.body.appendChild(link)

    // Simulate a click on the anchor element to trigger the download
    link.click()

    // Clean up the temporary anchor element
    document.body.removeChild(link)
  }

  const disableSubmit =
    !formik.touched.startDate &&
    !formik.touched.endDate &&
    !formik.touched.buyerId &&
    !formik.errors.startDate &&
    !formik.errors.endDate &&
    !formik.errors.buyerId

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Buyer Profitability Data</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={3} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Start Date</Form.Label>
                <div style={{ width: '100%' }}>
                  <DatePicker
                    clearIcon={null}
                    name="startDate"
                    onChange={(val: Date) => {
                      formik.setFieldValue('startDate', val)
                      formik.setFieldTouched('startDate', true, true)
                    }}
                    value={new Date(formik.values.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                {formik.touched.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDate}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col xs={12} sm={3} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set End Date</Form.Label>
                <DatePicker
                  clearIcon={null}
                  name="endDate"
                  onChange={(val: Date) => {
                    formik.setFieldValue('endDate', val)
                    formik.setFieldTouched('endDate', true, true)
                  }}
                  minDate={new Date(formik.values.startDate)}
                  maxDate={new Date()}
                  value={new Date(formik.values.endDate)}
                />
                {formik.touched.endDate && (
                  <Form.Control.Feedback type="invalid">
                    <div>{formik.errors.endDate}</div>
                  </Form.Control.Feedback>
                )}
              </Col>

              <Col xs={12} sm={3} style={{ margin: 0 }}>
                <Form.Group>
                  {isAdmin && <Form.Label htmlFor="location">Set Location:</Form.Label>}
                  {!isAdmin && canSeeInvoiceData && (
                    <Form.Label htmlFor="location">Location:</Form.Label>
                  )}
                  {isAdmin && (
                    <Form.Select
                      onChange={(e) => {
                        formik.handleChange(e)
                        formik.handleBlur(e)
                      }}
                      id="location"
                      name="location"
                      value={formik.values.location}
                    >
                      {locations.map((location) => {
                        return (
                          <option key={location.value} value={location.value}>
                            {location.text}
                          </option>
                        )
                      })}
                    </Form.Select>
                  )}
                  {!isAdmin && canSeeInvoiceData && (
                    <Form.Label htmlFor="location">{startCase(user.location)}</Form.Label>
                  )}
                </Form.Group>

                {formik.touched.location && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.location}
                  </Form.Control.Feedback>
                )}
              </Col>

              <Col xs={12} sm={3} style={{ margin: 0 }}>
                <Form.Group>
                  {isAdmin && <Form.Label htmlFor="location">Choose Buyer:</Form.Label>}
                  {!isAdmin && canSeeInvoiceData && (
                    <Form.Label htmlFor="location">Buyer:</Form.Label>
                  )}
                  {isAdmin && (
                    <Form.Select
                      onChange={(e) => {
                        formik.setFieldValue('buyerId', e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                      id="buyerId"
                      name="buyerId"
                      value={formik.values.buyerId}
                    >
                      <option value="all">All</option>
                      {buyers.map((buyer) => {
                        return (
                          <option key={buyer._id} value={buyer._id}>
                            {`${buyer.firstName} ${buyer.lastName}`}
                          </option>
                        )
                      })}
                    </Form.Select>
                  )}
                  {!isAdmin && canSeeInvoiceData && (
                    <Form.Label htmlFor="buyerId">
                      {user.firstName} {user.lastName}
                    </Form.Label>
                  )}
                </Form.Group>

                {formik.touched.buyerId && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.buyerId}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
          </Form>
          <Row className="mt-4">
            <Col xs={6} sm={4}>
              <Button
                type="submit"
                onClick={() => {
                  formik.submitForm()
                }}
                style={{ width: `100%` }}
                disabled={disableSubmit}
              >
                Get Data
              </Button>
            </Col>
            {historicalBuyerProfitabilityData.length > 0 && (
              <>
                <Col xs={6} sm={4}>
                  <Button className="me-3" variant="outline-info" onClick={downloadCsv}>
                    Export to CSV
                  </Button>
                </Col>
              </>
            )}
          </Row>
          {isLoading ? (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
              </Col>
            </Row>
          ) : (
            <div className="mt-4">
              {historicalBuyerProfitabilityData.length === 0 ? (
                <div>No items found.</div>
              ) : (
                <Col>
                  <Card>
                    <Table>
                      <thead>
                        <tr>
                          <th>Buyer</th>
                          <th>Invoice Date</th>
                          <th>Invoice Number</th>
                          <th>Invoice Credit</th>
                          <th>Serial Number</th>
                          <th>Price</th>
                          <th>Size Modifier</th>
                          <th>Quantity</th>
                          <th>Total Cost</th>
                          <th>Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historicalBuyerProfitabilityData.map((item, idx) => {
                          return (
                            <tr key={item.buyer + '_' + item._id + '_' + idx}>
                              <td>{item.buyer}</td>
                              <td>{format(new Date(item.dateAdded), 'P')}</td>
                              <td>{item.invoiceId}</td>
                              <td>
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }).format(item.invoiceCredit)}
                              </td>
                              <td>{item.serialNumber}</td>
                              <td>
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }).format(item.price)}
                              </td>
                              <td>{item.sizeModifier}</td>
                              <td>{item.quantity}</td>
                              <td>
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }).format(item.totalCost)}
                              </td>
                              <td>{startCase(item.location)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    historicalBuyerProfitabilityData: state.invoices.profitabilityData,
    user: state.user,
    buyers: state.admin.allBuyers,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    refetchBuyerProfitabilityData(body: { startDate: Date; endDate: Date; buyerId: string }) {
      dispatch(fetchBuyerProfitabilityData(body))
    },
    refetchAllBuyers() {
      dispatch(fetchAllBuyers())
    },
    clearProfitabilityData() {
      dispatch(clearProfitabilityData())
    },
  }
}

export default connect(mapState, mapDispatch)(BuyerProfitabilityData)
