import axios from 'axios'
import { getAuthCookie } from './cookies'
let baseURL = process.env.NODE_ENV !== 'development' ? '' : 'http://localhost:8080'
// let baseURL = process.env.NODE_ENV !== 'development' ? '' : 'https://46f6-209-122-241-223.ngrok.io'

export async function apiCaller(endpoint, method = 'get', body) {
  try {
    let c = getAuthCookie()
    let headers
    if (c && c.token) {
      headers = {
        'content-type': 'application/json',
        authorization: { c: c.token, t: '' },
        'X-Forwarded-Host': window.location.host,
      }
      headers.authorization = c.token // Token from cookie
    } else {
      headers = { 'content-type': 'application/json' }
    }
    const data = await axios({
      url: `${baseURL}/${endpoint}`,
      method,
      headers,
      data: body,
    })

    return data
  } catch (error) {
    if (error.response?.status === 401 && error.response?.data?.action === 'logout') {
      window.location = '/login'
    } else {
      throw error
    }
  }
}
