import React from 'react'
import { Button, Row, Col, Form, Container, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchCart, RootState, AppDispatch, generateExternalInvoice } from '../../store'
import { useEffect } from 'react'
import { useState } from 'react'
import { generateLabels } from '../util/utils'
import { Link } from 'react-router-dom'
import SignatureModal from './SignatureModal'
import { calculateTotalCost, calculateTotalPieces } from '../util/utils'
import { decimalToFraction } from '../util/utils'
import { ILabel } from '../../types/label'
import { ICart } from '../../types/cart'
import ScaleLoader from 'react-spinners/ScaleLoader'

interface CheckoutProps {
  cart: ICart
  fetchCart: () => void
  invoiceLoading: boolean
  generateExternalInvoice: (data: any) => void
  errorBody: { error: string; message: string }
  isPartner: boolean
}

const Checkout = ({
  cart,
  fetchCart,
  invoiceLoading,
  generateExternalInvoice,
  errorBody,
  isPartner,
}: CheckoutProps) => {
  const [showLabels, toggleLabels] = useState(true)
  const [labels, setLabels] = useState<ILabel[]>([])
  const [showSignature, setShowSignature] = useState(false)
  useEffect(() => {
    if (!cart) {
      fetchCart()
    }
    const labels = generateLabels(cart)

    setLabels(labels)
  }, [cart, invoiceLoading])

  const launchSignatureModal = () => {
    setShowSignature(true)
    generateExternalInvoice({ labels, cart, isPartner })
  }

  const { error, message } = errorBody

  const renderErrorMessage = () => {
    if (error === 'address_required' && !isPartner) {
      return (
        <Alert key="danger" variant="danger">
          Please add an address to the customer profile{' '}
          <Alert.Link href={`/admin/edit/user/${cart.customer._id}`}>here.</Alert.Link>
        </Alert>
      )
    }

    return (
      <Alert key="danger" variant="danger">
        {message}
      </Alert>
    )
  }

  if (invoiceLoading) {
    return (
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
        </Col>
      </Row>
    )
  }

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <h3>Checkout Summary</h3>
          {cart && (
            <div>
              <strong>Customer:</strong> {cart.customer.email}
            </div>
          )}
          {cart && (
            <div>
              <strong>Pieces:</strong> {calculateTotalPieces(cart.items)}
            </div>
          )}
          {cart && (
            <div>
              <strong>Total:</strong> {calculateTotalCost(cart.items, cart.credit)}
            </div>
          )}
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', maxHeight: 30 }}>
          <Form>
            <Row>
              Show Labels
              <Form.Check
                type="switch"
                id={cart && cart._id}
                defaultChecked={showLabels}
                onChange={() => toggleLabels(!showLabels)}
              />
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <strong>Serial #</strong>
        </Col>
        {!showLabels && (
          <Col>
            <strong>Size</strong>
          </Col>
        )}
        <Col>
          <strong>Quantity</strong>
        </Col>
        {!showLabels && (
          <Col>
            <strong>Cost</strong>
          </Col>
        )}
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <strong>Total</strong>
        </Col>
      </Row>
      <hr />
      {cart &&
        !showLabels &&
        cart.items.map((item) => {
          return (
            <div key={`${item._id}` + `${item.sizeModifier}`}>
              <Row>
                <Col>{item.serialNumber}</Col>
                <Col>{decimalToFraction(item.sizeModifier)}</Col>
                <Col>{item.quantity}</Col>
                <Col>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(item.price)}
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(item.quantity * item.price)}
                </Col>
              </Row>
              <hr />
            </div>
          )
        })}
      {cart && cart.credit > 0 && !showLabels && (
        <>
          <Row>
            <Col>Credit</Col>
            <Col></Col>
            <Col>1</Col>
            <Col>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cart.credit)}
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cart.credit)}
            </Col>
          </Row>
          <hr />
        </>
      )}

      {cart &&
        showLabels &&
        labels.map((item) => {
          return (
            <div key={`${item.label}` + `${item.quantity}` + `${item.cost}`}>
              <Row>
                <Col>{item.label}</Col>
                <Col>{item.quantity}</Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(item.cost)}
                </Col>
              </Row>
              <hr />
            </div>
          )
        })}
      <Row className="mt-3">
        <Col
          xs="12"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div>{cart && <strong>{calculateTotalCost(cart.items, cart.credit)}</strong>}</div>
        </Col>
      </Row>
      {error && renderErrorMessage()}
      <hr />
      <Row className="mb-3 mt-3">
        <Col xs="6">
          <Link to="/admin/cart">
            <Button>Back</Button>
          </Link>
        </Col>
        <Col xs="6">
          <Button
            onClick={() => launchSignatureModal()}
            style={{ width: '100%' }}
            variant="success"
          >
            Continue
          </Button>
        </Col>
      </Row>
      {showSignature && error !== 'address_required' && (
        <SignatureModal
          show={showSignature}
          toggle={() => setShowSignature(false)}
          cart={cart}
          labels={labels}
        />
      )}
      {cart && (
        <Row>
          <Col></Col>
        </Row>
      )}
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    cart: state.cart.data,
    user: state.user,
    isPartner: state.user.isPartner,
    allUsers: state.admin.allUsers,
    invoiceLoading: state.invoices.invoiceLoading,
    errorBody: state.invoices.error,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchCart() {
      dispatch(fetchCart())
    },
    generateExternalInvoice(body) {
      dispatch(generateExternalInvoice(body))
    },
  }
}

export default connect(mapState, mapDispatch)(Checkout)
