import cookie from 'react-cookies'

export const getAuthCookie = () => {
  let c = cookie.load('accurateConverterAuth')
  return c
}

export const setAuthCookie = (userId, token) => {
  const auth = { userId, token }
  cookie.save('accurateConverterAuth', auth, {
    path: '/',
    domain: 'accurateconverter.com',
  })
  cookie.save('accurateConverterAuth', auth, {
    path: '/',
    domain: 'accurate-converter-staging.herokuapp.com',
  })
  cookie.save('accurateConverterAuth', auth, {
    path: '/',
    domain: 'staging.accurateconverter.com',
  })
  cookie.save('accurateConverterAuth', auth, {
    path: '/',
    domain: 'app.accurateconverter.com',
  })
  cookie.save('accurateConverterAuth', auth, {
    path: '/',
    domain: 'localhost',
  })
  cookie.save('accurateConverterAuth', auth, {
    path: '/',
    domain: '46f6-209-122-241-223.ngrok.io',
  })
}

export const removeAuthCookie = () => {
  cookie.remove('accurateConverterAuth', {
    path: '/',
    domain: 'accurateconverter.com',
  })
  cookie.remove('accurateConverterAuth', {
    path: '/',
    domain: 'app.accurateconverter.com',
  })
  cookie.remove('accurateConverterAuth', {
    path: '/',
    domain: 'localhost',
  })
  cookie.remove('accurateConverterAuth', {
    path: '/',
    domain: '46f6-209-122-241-223.ngrok.io',
  })
  cookie.remove('accurateConverterAuth', {
    path: '/',
    domain: 'accurate-converter-staging.herokuapp.com',
  })
  cookie.remove('accurateConverterAuth', {
    path: '/',
    domain: 'staging.accurateconverter.com',
  })
}
