import axios from 'axios'
import { apiCaller } from '../utils/apiCaller'

/**
 * ACTION TYPES
 */
const GET_PRICES = 'GET_PRICES'
const GET_HISTORICAL_DATA = 'GET_HISTORICAL_DATA'

/**
 * INITIAL STATE
 */
const initialState = {
  prices: [],
  historical: {},
}

/**
 * ACTION CREATORS
 */
export const getPrices = (prices) => ({
  type: GET_PRICES,
  prices,
})

export const getHistoricalData = (data) => ({
  type: GET_HISTORICAL_DATA,
  data,
})

/**
 * THUNK CREATORS
 */
export const fetchPrices = () => async (dispatch) => {
  try {
    let res = await axios.get('/api/prices')
    let { prices } = res.data
    dispatch(getPrices(prices))
  } catch (err) {
    console.error(err)
  }
}

export const fetchMetalsData =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    try {
      const { data } = await apiCaller('api/prices/historical-metals-data', 'post', {
        startDate,
        endDate,
      })

      dispatch(getHistoricalData(data))
    } catch (err) {
      console.error(err)
    }
  }

/**
 * REDUCER
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRICES:
      return { ...state, prices: action.prices }
    case GET_HISTORICAL_DATA:
      return { ...state, historical: action.data }
    default:
      return state
  }
}
